/*
 * @Description:轮播图
 * @Autor: lichao
 * @Date: 2021-11-29 13:41:42
 * @LastEditors: lichao
 * @LastEditTime: 2022-02-17 15:27:56
 */
import { Carousel } from "antd";
import { useRef } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import "./index.less";

const Banner = (props) => {
  // console.log(props.bannerOptions);
  const banner = useRef();
  return (
    <div className="banner">
      <Carousel
        autoplay
        dots={false}
        autoplaySpeed={2500}
        draggable={true}
        ref={banner}
      >
        {props.bannerOptions &&
          props.bannerOptions.map((b, bi) => {
            return (
              <a className="banner-item" key={bi} href={b.url} >
                {/* <img src={b.text_image} alt="" /> */}
                <div
                  style={{
                    background: `url(${b.text_image}) no-repeat center center`,
                  }}
                ></div>
              </a>
            );
          })}
      </Carousel>
      {props.hasArrow ? (
        <>
          <span className="left" onClick={() => banner.current.prev()}>
            <LeftOutlined />
          </span>
          <span className="right" onClick={() => banner.current.next()}>
            <RightOutlined />
          </span>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Banner;
