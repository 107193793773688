import { useState, useEffect } from "react";
import { Pagination } from "antd";
import TextItem from "../../../components/TextItem";
import { getNewList } from "../../../api/home";
// 切页
function itemRender(current, type, originalElement) {
  if (type === "prev") {
    return <span>上一页</span>;
  }
  if (type === "next") {
    return <span>下一页</span>;
  }
  return originalElement;
}
// 通知公告
const useNotice = (page) => {
  const [notice, setNotice] = useState({ list: [], pageInfo: {} });
  useEffect(() => {
    // 获取公告数据
    getNewList({ location_id: 1, page }).then((res) => {
      const { code, data } = res;
      if (code === 200) setNotice(data);
    });
  }, [page]);
  return [notice];
};
const Notice = (props) => {
  // console.log(props,"通知公告的Props");
  // 通知公告
  const [noticePage, setNoticePage] = useState(1);
  const [notice] = useNotice(noticePage);

  return (
    <div className="information-container w1200">
      {notice.list.map((n, ni) => {
        return (
          <TextItem key={ni} {...n} meta={props.meta} pathname="/notice" />
        );
      })}
      <Pagination
        total={notice.pageInfo.dataTotal}
        itemRender={itemRender}
        pageSize={8}
        current={notice.pageInfo.current}
        showSizeChanger={false}
        onChange={(page, pageSize) => {
          setNoticePage(page);
        }}
      />
    </div>
  );
};

export default Notice;
