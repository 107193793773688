import { useState, useEffect } from "react";
import { Pagination } from "antd";
import TextImg from "../../../components/TextImg";
import { getNewList } from "../../../api/home";
// 切页
function itemRender(current, type, originalElement) {
  if (type === "prev") {
    return <span>上一页</span>;
  }
  if (type === "next") {
    return <span>下一页</span>;
  }
  return originalElement;
}
const useMedia = (page) => {
  const [media, setMedia] = useState({ list: [], pageInfo: {} });
  useEffect(() => {
    getNewList({ location_id: 3, page }).then((res) => {
      const { code, data } = res;
      if (code === 200) setMedia(data);
    });
  }, [page]);
  return [media];
};

const Media = (props) => {
  // 小镇要闻
  const [mediaPage, setMediaPage] = useState(1);
  const [media] = useMedia(mediaPage);
  return (
    <div className="information-container w1200">
      {media.list.map((n, ni) => {
        return <TextImg key={ni} {...n}  meta={props.meta}  pathname="/media"/>;
      })}
      <Pagination
        total={media.pageInfo.dataTotal}
        itemRender={itemRender}
        pageSize={8}
        current={media.pageInfo.current}
        showSizeChanger={false}
        onChange={(page, pageSize) => {
          setMediaPage(page);
        }}
      />
    </div>
  );
};

export default Media;
