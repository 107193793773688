/*
 * @Description: 
 * @Autor: lichao
 * @Date: 2021-11-26 09:08:47
 * @LastEditors: lichao
 * @LastEditTime: 2021-11-26 10:42:22
 */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "./index.css"
ReactDOM.render(
    <App />,
  document.getElementById('root')
);
