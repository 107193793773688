/*
 * @Description: 基金会简介
 * @Autor: lichao
 * @Date: 2021-12-02 11:53:39
 * @LastEditors: lichao
 * @LastEditTime: 2022-01-26 09:49:27
 */

import { useState, useEffect } from "react";
import { gethomelist } from "../../../api/home";

import "./index.less";
import { Tabs } from "antd";

const changeTabPane = (val) => {
  console.log(val,'val')
}

const FoundationIntroduce = () => {
  // 简介
  const [info, setInfo] = useState({});
  useEffect(() => {
    gethomelist().then((res) => {
      const { code, data } = res;
      // console.log(res);
      if (code === 200) {
        setInfo({ ...data.list[0] });
      }
    });
  }, []);
  return (
    <div className="foundation-introduce w1200" id="foundation-introduce-modal">
      <Tabs defaultActiveKey="1" type="card" onChange={changeTabPane}>
        <Tabs.TabPane tab="我们是谁" key="1">
          <div
            className="foundation-introduce-container"
            style={{
              background: "url(/image/foundationBack.png) left top no-repeat",
            }}
          >
            <h4>北京基金小镇公益基金会</h4>
            <div className="introduce-container-content">
              <div className="img-content">
                <img src={info.text_image} alt="" />
              </div>
              <div className="text-content">
                <p style={{ textIndent: "2em" }}>
                  {info.content}
                </p>
                {/*三个图*/}
                <div className="show-imgs">
                  <a href="https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/introduce/%E5%9F%BA%E9%87%91%E4%BC%9A%E6%B3%95%E4%BA%BA%E7%99%BB%E8%AE%B0%E8%AF%81%E4%B9%A6.jpg" target="_blank">
                    <img src="/image/foundation/one.jpg" alt=""/>
                  </a>
                  <a href="https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/introduce/%E7%A4%BE%E4%BC%9A%E7%BB%84%E7%BB%87%E7%AD%89%E7%BA%A7%E8%AF%84%E4%BC%B0%E7%89%8C%E5%8C%BE.jpg" target="_blank">
                    <img src="/image/foundation/two.jpg" alt=""/>
                  </a>
                  <a href="https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/introduce/%E7%A4%BE%E4%BC%9A%E7%BB%84%E7%BB%87%E7%AD%89%E7%BA%A7%E8%AF%84%E4%BC%B0%E8%AF%81%E4%B9%A6.jpg" target="_blank">
                    <img src="/image/foundation/three.jpg" alt=""/>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="使命愿景" key="2">
          <div
            className="foundation-introduce-container"
            style={{
              background: "url(/image/foundationBack.png) left top no-repeat",
            }}
          >
            <div className="two-tab">
              <p><span className="two-tab-span">使命：</span> 致力公益慈善事业，促进社会和谐与发展</p>
              <p><span className="two-tab-span">愿景：</span>增爱心财富，担社会责任，推动金融向善</p>
              <p><span className="two-tab-span">价值观： </span>平等、担当、合作、创新</p>
            </div>
          </div>

        </Tabs.TabPane>
        <Tabs.TabPane tab="组织机构" key="3">
          <div
            className="foundation-introduce-container"
            style={{
              background: "url(/image/foundationBack.png) left top no-repeat",
            }}
          >
            <div className="three-tab">
              <p className="three-title">理事会</p>
              <p>理事长：龚张微</p>
              <p>副理事长：周一晨</p>
              <p>秘书长、流动党支部书记：李欣</p>
              <p>理事会成员：龚张微、周一晨、钟钟、庞文军、张剑、杨晨茁、李欣</p>
              <p className="three-title">监事 <span style={{fontWeight:"normal"}}>卫安己</span></p>
            </div>
          </div>

        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default FoundationIntroduce;
