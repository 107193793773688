/*
 * @Description: 发展历程
 * @Autor: lichao
 * @Date: 2022-01-18 09:13:15
 * @LastEditors: lichao
 * @LastEditTime: 2022-02-17 15:27:28
 */
import { useState, useEffect, useCallback } from "react";
import { getadlist, getDevelopmentCourse } from "../../api/home";
import Banner from "../../components/Banner";
import { Swiper, SwiperSlide } from "swiper/react";
// 导入自动播放和分页器模块
import SwiperCore, { Pagination, Autoplay, Navigation } from "swiper";
// 导入swiper组件样式
import "swiper/swiper.less";
import "swiper/components/pagination/pagination.less";
import "./index.less";

SwiperCore.use([Pagination, Autoplay, Navigation]);

const useCourse = (year) => {
  console.log(year,'-----------')
  const [course, setCourse] = useState();
  useEffect(() => {
    getDevelopmentCourse({ year }).then((res) => {
      const { code, data } = res;
      if (code === 200) setCourse(data);
    });
  }, [year]);
  return [course];
};

const Memorabilia = () => {
  const [bannerlist, setbannerlist] = useState([]);
  /*新年的一年需要在此list中增加然后把activeIndex改为当前年*/
  const [yearlist] = useState([2015, 2016, 2017, 2018, 2019, 2020, 2021,2022,2023,2024]);
  const [activeIndex, setActiveIndex] = useState(9);
  const [course] = useCourse(yearlist[activeIndex]);

  let onSlideChange = useCallback(
    (swp) => {
      const { activeIndex: swpActiveIndex } = swp;
      setActiveIndex(swpActiveIndex);
    },
    [setActiveIndex]
  );

  useEffect(() => {
    getadlist({ position_id: 5 }).then((res) => {
      const {
        code,
        data: { banner_list },
      } = res;
      if (code === 200) {
        setbannerlist(banner_list);
      }
    });
  }, []);

  return (
    <div className="memorabilia">
      <Banner bannerOptions={bannerlist} />
      <div className="memorabilia-timeline w1200">
        {/* 年份轴 */}
        <div className="memorabilia-timeline-swiper">
          {/* 左箭头 */}
          <div className="swiper-button-prev">
              <img src="/image/homeVideoLeft.png" alt="" />

          </div>
          {/* 年份轮动 initialSlide 需要根据当前年手动选择 */}
          <Swiper
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
              disabledClass: "disable",
            }}
            centeredSlides={true}
            slidesPerView={5}
            initialSlide={9}
            spaceBetween={10}
            observer={true}
            observeParents={true}
            autoplay={false}
            onSlideChange={onSlideChange}
            slideToClickedSlide
            effect="coverflow"
          >
            {yearlist.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div
                    className={
                      activeIndex === index
                        ? "swiper-item swiper-item-active"
                        : "swiper-item"
                    }
                  >
                    <div className="swiper-item-active-nei">
                      <span>{item}</span>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          {/* 右箭头 */}
          <div className="swiper-button-next">

              <img src="/image/homeVideoLeft.png" alt="" />

          </div>
        </div>
        {/* 事迹 */}
        <div className="memorabilia-timeline-content">
          <div className="arrow-up-container">
            <img src="/image/arrow-up.png" alt="" />
          </div>
          {course &&
            Object.keys(course).reverse().map((item, index) => {
              return (
                <div className="memorabilia-list" key={index}>
                  <span className="mounth">{item}月</span>
                  <ul>
                    {course[item].map((c, ci) => {
                      return (
                        <li key={ci}>
                          <a href={c.pc_to_link} target="_blank" rel="noreferrer">
                            <div className="text-container">
                              <h4>{c.headline}</h4>
                              <p>{c.text}</p>
                            </div>
                            <img src={c.url} alt="" className="imginfo" />
                            <img
                              src="/image/circlecircle.png"
                              alt=""
                              className="circle"
                            />
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}

          {/* <ul className="memorabilia-list">
            <li>
              <Link to="/">
                <div className="text-container"></div>
                <span className="time-container">10月</span>
                <img src="" alt="" className="imginfo" />
                <img src="/image/circlecircle.png" alt="" className="circle" />
              </Link>
            </li>
          </ul> */}
        </div>
      </div>
    </div>
  );
};

export default Memorabilia;
// {
//   course &&
//     Object.keys(course).map((item, index) => {
//       return (
//         <li key={index}>
//           <Link to="/">
//             <div className="text-container"></div>
//             <span className="time-container">{item}月</span>
//             <img src="" alt="" className="imginfo" />
//             <img src="/image/circlecircle.png" alt="" className="circle" />
//           </Link>
//         </li>
//       );
//     });
// }
