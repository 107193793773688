/*
 * @Description: 共享中心
 * @Autor: wanghh
 * @Date: 2023年5月24日13:53:31
 * @LastEditors: wanghh
 * @LastEditTime: 2023年5月24日13:53:43
 */
import {useState, useEffect, useCallback, useRef} from "react";

import { Carousel,Tooltip} from "antd";
import "./index.less";
import { getSharingCenterList} from "../../api/home";

const Sharecenter = () => {
  const fsImgList = [
    { path: "/image/share_center/fangshan/01.jpg",id:1},
    { path: "/image/share_center/fangshan/02.jpg",id:2},
    { path: "/image/share_center/fangshan/03.jpg",id:3},
    { path: "/image/share_center/fangshan/04.jpg",id:4},
    { path: "/image/share_center/fangshan/05.jpg",id:5},
    { path: "/image/share_center/fangshan/06.jpg",id:6},
    { path: "/image/share_center/fangshan/07.jpg",id:7},
  ];
  const yqImgList = [
    { path: "/image/share_center/yanqing/01.jpg",id:1},
    { path: "/image/share_center/yanqing/02.jpg",id:2},
    { path: "/image/share_center/yanqing/03.jpg",id:3},
    { path: "/image/share_center/yanqing/04.jpg",id:4},
    { path: "/image/share_center/yanqing/05.jpg",id:5},
    { path: "/image/share_center/yanqing/07.jpg",id:6},
    { path: "/image/share_center/yanqing/08.jpg",id:7},
  ];
  const cyImgList = [
    { path: "/image/share_center/chaoyang/01.jpg",id:1},
    { path: "/image/share_center/chaoyang/03.jpg",id:3},
    { path: "/image/share_center/chaoyang/04.jpg",id:4},
    { path: "/image/share_center/chaoyang/05.jpg",id:5},
    { path: "/image/share_center/chaoyang/06.jpg",id:6},
    { path: "/image/share_center/chaoyang/07.jpg",id:7},
    { path: "/image/share_center/chaoyang/08.jpg",id:8},
  ]
  const fs_downlist = [
    { url: "http://www.bjfsh.gov.cn/zwgk/hygq/202304/t20230421_40060904.shtml", name: "【优化营商环境】“房山聚源计划”引才聚才十一条发布" },
    { url: "http://www.bjfsh.gov.cn/zwgk/tzgg/202304/t20230420_40060899.shtml", name: "关于开展“房山聚源计划”首批人才认定工作的通知" },
    { url: "http://www.bjfsh.gov.cn/ztzx/wqzt/zdlyzfgkzt/qjqd/fshcyjy/wgjyzc/202209/t20220916_40051112.shtml", name: "房山区稳就业专项行动“30条”落实措施" },
    { url: "http://www.bjfsh.gov.cn/zwgk/qzfcxjsw/fgwj_1099/qtwj_1105/202206/t20220614_40046651.shtml", name: "房山区关于优化住房支持政策服务保障人才发展的实施细则" },
  ];
  const yq_downlist = [
    { url: "https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/webpc/admin_upload_file/%E4%B8%AD%E5%85%B3%E6%9D%91%E7%A7%91%E6%8A%80%E5%9B%AD%E5%8C%BA%E5%BB%B6%E5%BA%86%E5%9B%AD%E7%AE%A1%E5%A7%94%E4%BC%9A%E5%85%B3%E4%BA%8E%E6%94%AF%E6%8C%81%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%EF%BC%88%E4%B8%AD%E5%85%B3%E6%9D%91%E5%BB%B6%E5%BA%86%E5%9B%AD%EF%BC%89%E5%85%B1%E4%BA%AB%E4%B8%AD%E5%BF%83%E4%BA%BA%E6%89%8D%E5%BC%95%E8%BF%9B%E5%B7%A5%E4%BD%9C%E7%9A%84%E5%87%BD-2023-5-31.pdf", name: "《关于支持北京基金小镇（中关村延庆园）共享中心人才引进工作的函》" },
    { url: "http://czj.beijing.gov.cn/zwxx/zcfg/zcqtwj/202210/t20221010_2832289.html", name: "《财政部 税务总局发展改革委 证监会关于中关村国家自主创新示范区公司型创业投资企业有关企业所得税试点政策的通知财税〔2020〕63号》"},
    { url: "http://www.bjyq.gov.cn/yanqing/zwgk/gfxwj/gfxwj86/13118317/index.shtml", name: "北京市延庆区人民政府办公室印发《延庆区关于实施“三大工程”进一步支持和服务高新技术企业发展的 工作方案》的通知" },
    { url: "http://www.bjyq.gov.cn/yanqing/zwgk/gfxwj/qzfbmwj/13121479/index.shtml", name: "关于加快延庆区高新技术企业创新发展的支持办法" },
    { url: "http://www.bjyq.gov.cn/yanqing/zwgk/gfxwj/qzfbwj/2992762/index.shtml", name: "北京市延庆区人民政府办公室关于印发《延庆区落实<北京市培育和激发市场主体活力持续优化营商环境实施方案>重点任务分工方案》的通知" },
  ]
  const [cyDownList,setCyDownList] = useState([])
  useEffect(() => {
    getSharingCenterList({location_id:17,page:1,limit:5}).then(res => {
      const {code,data} = res;
      if (code === 200) setCyDownList(data.list)
    })
  }, []);

  return (
    <div className="share-center">
      <div className="banner">
        <Carousel
          autoplay
          dots={false}
          autoplaySpeed={2500}
          draggable={true}
        >
          <a className="banner-item">
            <img src='/image/share_center/center-banner.png' alt="" />
          </a>
        </Carousel>
        <h1 className="big-title">共享中心</h1>
      </div>
      <div className="share-container">
        {/*顶部介绍*/}
        <div className="top-introduce">
          <div className="introduce-title">
            <img src="/image/share_center/share-title-bg.png" alt=""/>
            <h2 className="share-title">多点布局 跨区域协同发展</h2>
          </div>
          <div className="introdece-p">
            <p>北京基金小镇多点布局，建设跨区域协同发展的共享中心。发挥共享中心及战略合作伙伴在产业、资源、政策、服务等方面优势，为基金机构、高精尖企业在专业服务、投融资、交流培训、研究创新等方面提供更加精准、专业、智能的服务，与业界开放共生、价值共享，协同发展。</p>
          </div>
        </div>
        {/*list*/}
        <div className="list">
          <div className="list-container">
            <div className="list-imgs">
              <Carousel autoplay autoplaySpeed={2500}>
                {
                  fsImgList.map((item, index) => {
                    return (
                      <img src={item.path} alt="" key={index}/>
                    )
                  })
                }
              </Carousel>
            </div>
            <div className="list-texts">
              <div className="texts-top">
                <img src="/image/share_center/title-bg.png" alt=""/>
                <h2 className="right-title">北京基金小镇（房山）共享中心</h2>
              </div>
              <div className="texts-mid">
                <p>政策文件</p>
                <ul className="downloads">
                  {/*文件下载列表*/}
                  {
                    fs_downlist.map((item,index) => {
                      return (
                        <li className="download-item" key={item.id}>
                          <img src="/image/share_center/download.png" alt=""/>
                            <a href={item.url} target="_blank">{item.name}</a>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
              <div className="texts-bottom">
                <a href="https://apply.beijingfundtown.com/home/lists#/login/edge" target="_blank">申请入驻</a>
              </div>
            </div>
          </div>
        </div>
        <div className="list">
          <div className="list-container">
            <div className="list-texts">
              <div className="texts-top">
                <img src="/image/share_center/title-bg.png" alt=""/>
                <h2 className="right-title">北京基金小镇（中关村延庆园）共享中心</h2>
              </div>
              <div className="texts-mid">
                <p>政策文件</p>
                <ul className="downloads">
                  {/*文件下载列表*/}
                  {
                    yq_downlist.map((item, index) => {
                      return (
                        <li className="download-item" key={item.id}>
                          <img src="/image/share_center/download.png" alt=""/>
                          <Tooltip title={item.name.length>28?item.name:false} placement="topLeft" overlayInnerStyle={{minWidth:'470px',bottom:'-10px'}}>
                            <a href={item.url} target="_blank">{item.name}</a>
                          </Tooltip>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
              <div className="texts-bottom">
                <a href="https://apply.beijingfundtown.com/home/lists#/login/edge" target="_blank">申请入驻</a>
              </div>
            </div>
            <div className="list-imgs">
              <Carousel autoplay autoplaySpeed={2500}>
                {
                  yqImgList.map((item, index) => {
                    return (
                      <img src={item.path} alt="" key={index}/>
                    )
                  })
                }
              </Carousel>
            </div>

          </div>
        </div>
        <div className="list">
          <div className="list-container">
            <div className="list-imgs">
              <Carousel autoplay autoplaySpeed={2500}>
                {
                  cyImgList.map((item, index) => {
                    return (
                      <img src={item.path} alt="" key={index}/>
                    )
                  })
                }
              </Carousel>
            </div>
            <div className="list-texts">
              <div className="texts-top">
                <img src="/image/share_center/title-bg.png" alt=""/>
                <h2 className="right-title">北京基金小镇（中关村朝阳园）共享中心</h2>
              </div>
              <div className="texts-mid">
                <ul className="downloads">
                  {
                     cyDownList.map((item, index) => {
                      return (
                        <li className="download-item" key={item.id}>
                          <i>●</i>
                          <Tooltip title={item.name.length>28?item.name:false} placement="topLeft" overlayInnerStyle={{minWidth:'470px',bottom:'-10px'}}>
                            <a href={item.to_url} target="_blank">{item.name}</a>
                          </Tooltip>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
              <div className="texts-bottom">
                <a href="http://pc.beijingfundtown.com/news/focus" target="_blank" className="more-button">更多+</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sharecenter;
