/*
 * @Description:小镇动态中部tab切换
 * @Autor: lichao
 * @Date: 2021-11-29 13:59:11
 * @LastEditors: lichao
 * @LastEditTime: 2022-02-17 15:28:27
 */

import { NavLink, withRouter } from "react-router-dom";
import { Breadcrumb } from "antd";
import "./index.less";

const Crumbs = (props) => {
  // console.log(props, "Crumbs的Props");
  const {
    NewBreadcrumb,
    pathOptions,
    isWidth
  } = props;
   //console.log(isWidth,'----isWidth');

  return (
    <div className="crumbs">
      <div className="left"></div>
      <div className={isWidth ? "core w1500" : "core"}>
        <div className="core-crumbs">
          <div className="core-crumbs-icon">
            <img src="/image/crumbs-home.png" alt="" />
          </div>

          <Breadcrumb separator=">">
            {NewBreadcrumb.map((crumb, index) => (
              <Breadcrumb.Item href={crumb.path} key={index}>
                {crumb.meta}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </div>
        <ul className="core-nav">
          {pathOptions.map((c, ci) => (
            // className={active === ci ? "core-nav-active" : ""}
            <li key={ci}>
              <NavLink to={c.path} activeClassName="core-nav-active">
                {c.name}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
      <div className="right"></div>
    </div>
  );
};

export default withRouter(Crumbs);
