/*
 * @Description: 
 * @Autor: lichao
 * @Date: 2021-11-29 15:00:38
 * @LastEditors: lichao
 * @LastEditTime: 2022-02-07 15:10:19
 */
import { Link } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import "./index.less";
const TextItem = (props) => {
  // console.log(props);
  return (
    <Link
      className="text-item"
      to={{ pathname: `${props.pathname}/${props.id}` }}
      target="_blank"
    >
      <div className="text-item-time">{props.public_time}</div>
      <div className="text-item-title">{props.name}</div>
      <div className="text-item-watch">
        <EyeOutlined />
      </div>
    </Link>
  );
};
export default TextItem;
