/*
 * @Description: 公益基金会
 * @Autor: lichao
 * @Date: 2021-12-01 13:39:25
 * @LastEditors: lichao
 * @LastEditTime: 2022-02-17 15:27:00
 */
import { useState, useEffect } from "react";
import { withRouter, Route, Redirect, Switch } from "react-router-dom";
import { getadlist } from "../../api/home";
import Banner from "../../components/Banner";
import Crumbs from "../../components/Crumbs";
import FoundationIntroduce from "./FoundationIntroduce";
import FoundationProject from "./FoundationProject";
import FoundationInfo from "./FoundationInfo";
import FoundationActivity from "./FoundationActivity";
import FoundationContact from "./FoundationContact";

const pathOptions = [
  { name: "基金会简介", path: "/foundation/introduce" },
  { name: "项目及活动", path: "/foundation/project" },
  { name: "党建工作", path: "/foundation/activity" },
  { name: "信息公开", path: "/foundation/info" },
  { name: "联系我们", path: "/foundation/contact" },
];

const Foundation = (props) => {
  const {
    location: { pathname },
  } = props;
  const BreadcrumbOptions = pathname.slice(1).split("/");
  const NewBreadcrumb = BreadcrumbOptions.map((curmb) => {
    let transCurmb = "";
    switch (curmb) {
      case "foundation":
        transCurmb = { meta: "公益基金会", path: "/foundation" };
        break;
      case "introduce":
        transCurmb = { meta: "基金会简介", path: "/foundation/introduce" };
        break;
      case "project":
        transCurmb = { meta: "项目及活动", path: "/foundation/project" };
        break;
      case "activity":
        transCurmb = { meta: "党建工作", path: "/foundation/activity" };
        break;
      case "info":
        transCurmb = { meta: "信息公开", path: "/foundation/info" };
        break;
      case "contact":
        transCurmb = { meta: "联系我们", path: "/foundation/contact" };
        break;
      default:
        break;
    }
    return transCurmb;
  });
  const [bannerlist, setbannerlist] = useState([]);
  useEffect(() => {
    getadlist({ position_id: 7 }).then((res) => {
      const {
        code,
        data: { banner_list },
      } = res;
      if (code === 200) {
        setbannerlist(banner_list);
      }
    });
  }, []);
  return (
    <div>

      <Banner bannerOptions={bannerlist} />
      <Crumbs NewBreadcrumb={NewBreadcrumb} pathOptions={pathOptions} isWidth={true}/>
      <Switch>
        <Route
          path="/foundation/introduce"
          render={(props) => <FoundationIntroduce meta="基金会介绍" />}
          exact={false}
        ></Route>
        <Route
          path="/foundation/info"
          render={(props) => <FoundationInfo meta="信息公开" />}
          exact={false}
        ></Route>
        <Route
          path="/foundation/project"
          render={(props) => <FoundationProject meta="项目及活动" />}
          exact={false}
        ></Route>
        <Route
          path="/foundation/activity"
          render={(props) => <FoundationActivity meta="党建工作" />}
          exact={false}
        ></Route>
        <Route
          path="/foundation/contact"
          render={(props) => <FoundationContact meta="联系我们" />}
          exact={false}
        ></Route>
        <Redirect path="/foundation" to="/foundation/introduce"></Redirect>
      </Switch>
    </div>
  );
};

export default withRouter(Foundation);
