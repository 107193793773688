/*
 * @Description: 党建
 * @Autor: lichao
 * @Date: 2022-01-18 09:13:15
 * @LastEditors: lichao
 * @LastEditTime: 2022-02-17 15:27:44
 */
import { useState, useEffect } from "react";
import { getadlist, partyBuildingatalists } from "../../api/home";
import { Pagination } from "antd";
import Banner from "../../components/Banner";
import TitleL from "../../components/TitileL";
import LiItem from "./LiItem";
import ImgItem from "./ImgItem";
import "./index.less";

// 切页
function itemRender(current, type, originalElement) {
  if (type === "prev") {
    return <span>上一页</span>;
  }
  if (type === "next") {
    return <span>下一页</span>;
  }
  return originalElement;
}

const useList = (page, location_id) => {
  const [list, setList] = useState({
    list: [],
    pageInfo: {},
  });
  useEffect(() => {
    partyBuildingatalists({ location_id: location_id, page }).then((res) => {
      const { code, data } = res;
      if (code === 200) setList(data);
    });
  }, [page, location_id]);
  return [list];
};

const useAdList = (position_id) => {
  const [list, setList] = useState();
  useEffect(() => {
    getadlist({ position_id: position_id }).then((res) => {
      const { code, data } = res;
      if (code === 200) setList(data);
    });
  }, [position_id]);
  return [list];
};

const Patybuilding = () => {
  const [propagandaAd] = useAdList(12);
  const [propagandaCenterAd] = useAdList(11);
  const [studyAd] = useAdList(13);
  // const [propagandaAd] = useAdList(8);
  // src={propagandaAd.banner_list[0].text_image}
  const [bannerlist, setbannerlist] = useState([]);
  // 党宣
  const [propagandaPage, setPropagandaPage] = useState(1);
  const [propagandaList] = useList(propagandaPage, 15);
  // 党建学习
  const [studyPage, setStudyPage] = useState(1);
  const [study] = useList(studyPage, 16);
  // 党宣活动
  const [activityPage, setActivityPage] = useState(1);
  const [activity] = useList(activityPage, 11);
  useEffect(() => {
    getadlist({ position_id: 8 }).then((res) => {
      const {
        code,
        data: { banner_list },
      } = res;
      if (code === 200) {
        setbannerlist(banner_list);
      }
    });
  }, []);
  return (
    <div className="patybuilding">
      <Banner bannerOptions={bannerlist} />
      <TitleL title="党宣工作" />
      <div className="propaganda w1200">
        <img
          src={
            propagandaAd && propagandaAd.banner_list.length
              ? propagandaAd.banner_list[0].text_image
              : ""
          }
          alt=""
          className="left-img"
        />
        <div className="right-content">
          <img src="/image/shadowbg.png" alt="" />
          <ul className="right-content-list">
            {propagandaList.list.map((item, i) => {
              return <LiItem key={i} {...item} />;
            })}
          </ul>
          <Pagination
            total={propagandaList.pageInfo.dataTotal}
            itemRender={itemRender}
            pageSize={6}
            current={propagandaList.pageInfo.current}
            showSizeChanger={false}
            onChange={(page, pageSize) => {
              setPropagandaPage(page);
            }}
          />
        </div>
      </div>
      <div className="advertisement w1200">
        <img
          src={
            propagandaCenterAd
              ? propagandaCenterAd.banner_list[0]
                ? propagandaCenterAd.banner_list[0].text_image
                : ""
              : ""
          }
          alt=""
        />
      </div>
      <TitleL title="党建学习" />
      <div className="study w1200">
        <div className="study-img">
          {studyAd && studyAd.banner_list.length
            ? studyAd.banner_list.map((item, index) => {
                return <img src={item.text_image?item.text_image:""} alt="" key={index} />;
              })
            : ""}
        </div>
        <div className="study-list">
          <ul className="study-list-ul">
            {study.list.map((item, i) => {
              return <LiItem key={i} {...item} />;
            })}
          </ul>

          <Pagination
            total={study.pageInfo.dataTotal}
            itemRender={itemRender}
            pageSize={6}
            current={study.pageInfo.current}
            showSizeChanger={false}
            onChange={(page, pageSize) => {
              setStudyPage(page);
            }}
          />
        </div>
      </div>
      <TitleL title="党建活动" />
      <div className="activity w1200">
        {activity.list.map((item, i) => {
          return <ImgItem key={i} {...item} pathname="" />;
        })}
        <Pagination
          total={activity.pageInfo.dataTotal}
          itemRender={itemRender}
          pageSize={4}
          current={activity.pageInfo.current}
          showSizeChanger={false}
          onChange={(page, pageSize) => {
            setActivityPage(page);
          }}
        />
      </div>
    </div>
  );
};

export default Patybuilding;
