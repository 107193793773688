/*
 * @Description:
 * @Autor: lichao
 * @Date: 2021-11-29 15:30:49
 * @LastEditors: lichao
 * @LastEditTime: 2022-02-07 15:12:33
 */
import { Link } from "react-router-dom";
import "./index.less";

// location_id用来判断是视频类型还是图文类型
const Mask = (props) => {
  return props.location_id !== 5 ? (
    <div className="isImg">
      <img src="/image/isImgArrow.png" alt="" />
    </div>
  ) : (
    <div className="isVideo">
      <img src="/image/24gf-playSquare.png" alt="" />
    </div>
  );
};

const TextImg = (props) => {
  const { location_id, public_time, create_time } = props;
  //let time = public_time ? public_time : create_time;
  // 2024年4月3日17:17:10 全部改成创建时间
  let time = create_time;
  const formatTime = time.split("-");
  //console.log(props, "eeeeeeeeee");
  return (
    <Link
      className="text-img"
      to={{ pathname: `${props.pathname}/${props.id}` }}
      target="_blank"
    >
      <div className="text-img-box">
        <Mask location_id={location_id} />
        <img src={props.image} alt="" className="text-img-item" />
      </div>
      <div className="text-img-title">{props.name}</div>
      <div className="text-img-time">
        <span className="day">{formatTime[2]}</span>
        <span className="line"></span>
        <span className="year">
          {formatTime[0]}.{formatTime[1]}
        </span>
      </div>
    </Link>
  );
};

export default TextImg;
