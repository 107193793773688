/*
 * @Description:nav
 * @Autor: lichao
 * @Date: 2021-11-26 09:54:59
 * @LastEditors: lichao
 * @LastEditTime: 2022-02-17 15:28:09
 */
import { useState, useEffect } from "react";
import { Link, withRouter, NavLink } from "react-router-dom";
import "./index.less";

const NavigationBarOptions = [
  { name: "小镇介绍", path: "/home" },
  { name: "发展历程", path: "/memorabilia" },
  { name: "共享中心", path: "/sharecenter" },
  { name: "小镇动态", path: "/news" },
  { name: "公益基金会", path: "/foundation" },
  { name: "党建工作", path: "/partybuilding" },
];

const NavigationBar = (props) => {
  const {
    location: { pathname },
  } = props;
  // activeNavPath选中的导航对应的path
  // 设置activeNavPath
  const [activeNavPath, setActiveNavPath] = useState("");
  // 加载时默认设置当前的path
  useEffect(() => {
    let isNews =
      pathname.indexOf("news") > 0 ||
      pathname.indexOf("notice") > 0 ||
      pathname.indexOf("media") > 0 ||
      pathname.indexOf("focus") > 0;
    let isFou =
      pathname.indexOf("foundation") > 0 ||
      pathname.indexOf("introduce") > 0 ||
      pathname.indexOf("project") > 0 ||
      pathname.indexOf("activity") > 0 ||
      pathname.indexOf("info") > 0;
    if (isNews) {
      setActiveNavPath("/news");
    } else if (isFou) {
      setActiveNavPath("/foundation");
    } else {
      console.log(pathname);

      setActiveNavPath(pathname);
    }
  }, [pathname]);
  return (
    <header className="navigationBar">
      <div className="navigationBar-container">
        <Link to="/">
          <img src="/image/header-logo.png" alt="" />
        </Link>
        <ul>
          {NavigationBarOptions.map((nav, navindex) => (
            <li
              key={navindex}
              className="navigationBar-item"
              onClick={() => setActiveNavPath(nav.path)}
            >
              <NavLink
                to={nav.path}
                activeClassName="activeNavLink"
                className={activeNavPath === nav.path ? "activeNavLink" : ""}
              >
                {nav.name}
                <span></span>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </header>
  );
};

export default withRouter(NavigationBar);
