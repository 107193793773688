/*
 * @Description:
 * @Autor: lichao
 * @Date: 2021-11-26 16:47:46
 * @LastEditors: lichao
 * @LastEditTime: 2021-12-10 09:23:51
 */
/*
 * @Description: root
 * @Autor: lichao
 * @Date: 2021-11-26 09:08:47
 * @LastEditors: lichao
 * @LastEditTime: 2021-11-30 17:16:59
 */
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import NavigationBar from "./components/NavigationBar";
import SiteMapFooter from "./components/SiteMapFooter";

import routes from "./router/index";

const App = () => {
  let isUrl = window.location.href.includes('bjftcf.com');
  console.log(isUrl,'--isUrl')
  return (
    <Router>
      {isUrl?'':<NavigationBar />}
      <Switch>
        {routes.map((r, i) => {
          if (r.exact) {
            return (
              <Route
                key={i}
                path={r.path}
                render={(props) => {
                  document.title = r.meta.name;
                  return <r.component meta={r.meta} />;
                }}
                exact={r.exact}
              ></Route>
            );
          } else {
            return (
              <Route
                key={i}
                path={r.path}
                exact={r.exact}
                render={(props) => {
                  document.title = r.meta.name;
                  return <r.component meta={r.meta} />;
                }}
              ></Route>
            );
          }
        })}
        {/*如果是公益基金会的网址，则默认跳转到公益基金会的页面*/}
        {
          isUrl?<Redirect path="/" to="/foundation/introduce"></Redirect>:<Redirect path="/" to="/home"></Redirect>
        }

      </Switch>
      <SiteMapFooter />
    </Router>
  );
};

export default App;
