import "./index.less"
const TitleR = (props) => {
  return (
    <div className="titlel-title">
      <span
        style={{
          background: "url(/image/welfare-title-bg.png) no-repeat",
        }}
      >
        {props.title}
      </span>
      <img src="/image/pigeon.png" alt="" />
        
    </div>
  );
};
export default TitleR;
