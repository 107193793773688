/*
 * @Description: 信息公开
 * @Autor: lichao
 * @Date: 2021-12-02 11:55:02
 * @LastEditors: lichao
 * @LastEditTime: 2021-12-08 11:51:10
 */
// import { Button } from "antd";
import { useState, useEffect } from "react";

import { EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import TitleR from "../../../components/TitileR";
import { disclosuredatalists } from "../../../api/home";
import "./index.less";
import {Tabs} from "antd";

const useDisclosuredatalists = (page, location_id) => {
  const [Disclosuredatalists, setDisclosuredatalists] = useState({
    list: [],
    pageInfo: {},
  });
  useEffect(() => {
    disclosuredatalists({ location_id: location_id }).then((res) => {
      const { code, data } = res;
      if (code === 200) setDisclosuredatalists(data);
    });
  }, [page,location_id]);
  return [Disclosuredatalists];
};
const changeTabPane = (val) => {
  console.log(val,'val')
}
const FoundationInfo = () => {
  // 基金会章程
  const [constitution] = useDisclosuredatalists(null, 14);

  // 管理制度数据
  const [system] = useDisclosuredatalists(null, 9);
  // 年度报告数据  文件在存储桶
  const annualReport = [
    {name: '北京基金小镇公益基金会2023年度工作报告.pdf', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/yearly_report/%E5%9F%BA%E9%87%91%E4%BC%9A2023%E5%B9%B4%E5%BA%A6%E6%8A%A5%E5%91%8A.pdf'},
    {name: '北京基金小镇公益基金会2022年度工作报告.pdf', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/yearly_report/%E5%9F%BA%E9%87%91%E4%BC%9A2022%E5%B9%B4%E5%BA%A6%E6%8A%A5%E5%91%8A.pdf'},
    {name: '北京基金小镇公益基金会2021年度工作报告.pdf', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/yearly_report/%E5%9F%BA%E9%87%91%E4%BC%9A2021%E5%B9%B4%E5%BA%A6%E6%8A%A5%E5%91%8A.pdf'},
    {name: '北京基金小镇公益基金会2020年度工作报告.pdf', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/yearly_report/%E5%9F%BA%E9%87%91%E4%BC%9A2020%E5%B9%B4%E5%BA%A6%E6%8A%A5%E5%91%8A.pdf'},
    {name: '北京基金小镇公益基金会2019年度工作报告.pdf', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/yearly_report/%E5%9F%BA%E9%87%91%E4%BC%9A2019%E5%B9%B4%E5%BA%A6%E6%8A%A5%E5%91%8A.pdf'},
    {name: '北京基金小镇公益基金会2018年度工作报告.pdf', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/yearly_report/%E5%9F%BA%E9%87%91%E4%BC%9A2018%E5%B9%B4%E5%BA%A6%E6%8A%A5%E5%91%8A.pdf'},
  ]
  // 审计报告
  const auditReport = [
    {name: '2023年北京基金小镇公益基金会审计报告.pdf', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/audit_report/2023%E5%B9%B4%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E5%AE%A1%E8%AE%A1%E6%8A%A5%E5%91%8A.pdf'},
    {name: '2023年北京基金小镇公益基金会专项信息审核报告.pdf', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/audit_report/2023%E5%B9%B4%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E4%B8%93%E9%A1%B9%E4%BF%A1%E6%81%AF%E5%AE%A1%E6%A0%B8%E6%8A%A5%E5%91%8A.pdf'},
    {name: '2022年北京基金小镇公益基金会换届财务审计报告.pdf', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/audit_report/2022%E5%B9%B4%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E6%8D%A2%E5%B1%8A%E8%B4%A2%E5%8A%A1%E5%AE%A1%E8%AE%A1%E6%8A%A5%E5%91%8A.pdf'},
    {name: '2022年北京基金小镇公益基金会审计报告.pdf', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/audit_report/2022%E5%B9%B4%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E5%AE%A1%E8%AE%A1%E6%8A%A5%E5%91%8A.pdf'},
    {name: '2022年北京基金小镇公益基金会专项信息审核报告.pdf', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/audit_report/2022%E5%B9%B4%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E4%B8%93%E9%A1%B9%E4%BF%A1%E6%81%AF%E5%AE%A1%E6%A0%B8%E6%8A%A5%E5%91%8A.pdf'},
    {name: '2021年北京基金小镇公益基金会审计报告.pdf', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/audit_report/2021%E5%B9%B4%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E5%AE%A1%E8%AE%A1%E6%8A%A5%E5%91%8A.pdf'},
    {name: '2021年北京基金小镇公益基金会专项信息审核报告.pdf', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/audit_report/2021%E5%B9%B4%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E4%B8%93%E9%A1%B9%E4%BF%A1%E6%81%AF%E5%AE%A1%E6%A0%B8%E6%8A%A5%E5%91%8A.pdf'},
    {name: '2020年北京基金小镇公益基金会审计报告.pdf', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/audit_report/2020%E5%B9%B4%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E5%AE%A1%E8%AE%A1%E6%8A%A5%E5%91%8A.pdf'},
    {name: '2020年北京基金小镇公益基金会专项信息审核报告.pdf', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/audit_report/2020%E5%B9%B4%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E4%B8%93%E9%A1%B9%E4%BF%A1%E6%81%AF%E5%AE%A1%E6%A0%B8%E6%8A%A5%E5%91%8A.pdf'},
    {name: '2019年北京基金小镇公益基金会审计报告.pdf', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/audit_report/2019%E5%B9%B4%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E5%B9%B4%E5%BA%A6%E5%AE%A1%E8%AE%A1%E6%8A%A5%E5%91%8A.pdf'},
    {name: '2019年北京基金小镇公益基金会专项信息审核报告.pdf', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/audit_report/2019%E5%B9%B4%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E4%B8%93%E9%A1%B9%E4%BF%A1%E6%81%AF%E5%AE%A1%E6%A0%B8%E6%8A%A5%E5%91%8A.pdf'},
    {name: '2018年北京基金小镇公益基金会审计报告.pdf', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/audit_report/2018%E5%B9%B4%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E5%AE%A1%E8%AE%A1%E6%8A%A5%E5%91%8A.pdf'},
    {name: '2018年北京基金小镇公益基金会专项信息审核报告.pdf', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/audit_report/2018%E5%B9%B4%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E4%B8%93%E9%A1%B9%E4%BF%A1%E6%81%AF%E5%AE%A1%E6%A0%B8%E6%8A%A5%E5%91%8A.pdf'},
    {name: '2017年北京基金小镇公益基金会审计报告.pdf', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/audit_report/2017%E5%B9%B4%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E5%AE%A1%E8%AE%A1%E6%8A%A5%E5%91%8A.pdf'},
    {name: '2017年北京基金小镇公益基金会专项信息审计报告.pdf', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/audit_report/2017%E5%B9%B4%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E4%B8%93%E9%A1%B9%E4%BF%A1%E6%81%AF%E5%AE%A1%E8%AE%A1%E6%8A%A5%E5%91%8A.pdf'},
  ]
  // 公示公文
  const publicOfficials = [
    {name: '北京基金小镇公益基金会诚信自律承诺书.pdf', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/publicity/%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E8%AF%9A%E4%BF%A1%E8%87%AA%E5%BE%8B%E6%89%BF%E8%AF%BA%E4%B9%A6.pdf'},
  ]

  // 章程制度
  const constitutionList = [
    {name: '北京基金小镇公益基金会章程', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/constitution/%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E7%AB%A0%E7%A8%8B.pdf'},
    {name: '北京基金小镇公益基金会项目管理制度', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/constitution/1-%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E9%A1%B9%E7%9B%AE%E7%AE%A1%E7%90%86%E5%88%B6%E5%BA%A6.pdf'},
    {name: '北京基金小镇公益基金会理事会工作制度', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/constitution/2-%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E7%90%86%E4%BA%8B%E4%BC%9A%E5%B7%A5%E4%BD%9C%E5%88%B6%E5%BA%A6.pdf'},
    {name: '北京基金小镇公益基金会财务管理制度', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/constitution/3-%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E8%B4%A2%E5%8A%A1%E7%AE%A1%E7%90%86%E5%88%B6%E5%BA%A6.pdf'},
    {name: '北京基金小镇公益基金会人事管理制度', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/constitution/4-%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E4%BA%BA%E4%BA%8B%E7%AE%A1%E7%90%86%E5%88%B6%E5%BA%A6.pdf'},
    {name: '北京基金小镇公益基金会薪酬管理制度', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/constitution/5-%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E8%96%AA%E9%85%AC%E7%AE%A1%E7%90%86%E5%88%B6%E5%BA%A6.pdf'},
    {name: '北京基金小镇公益基金会绩效考核管理办法', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/constitution/6-%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E7%BB%A9%E6%95%88%E8%80%83%E6%A0%B8%E7%AE%A1%E7%90%86%E5%8A%9E%E6%B3%95.pdf'},
    {name: '北京基金小镇公益基金会重大事项报告制度', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/constitution/7-%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E9%87%8D%E5%A4%A7%E4%BA%8B%E9%A1%B9%E6%8A%A5%E5%91%8A%E5%88%B6%E5%BA%A6.pdf'},
    {name: '北京基金小镇公益基金会档案管理办法', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/constitution/8-%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E6%A1%A3%E6%A1%88%E7%AE%A1%E7%90%86%E5%8A%9E%E6%B3%95.pdf'},
    {name: '北京基金小镇公益基金会信息公布办法', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/constitution/9-%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E4%BF%A1%E6%81%AF%E5%85%AC%E5%B8%83%E5%8A%9E%E6%B3%95.pdf'},
    {name: '北京基金小镇公益基金会印章管理规定', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/constitution/10-%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E5%8D%B0%E7%AB%A0%E7%AE%A1%E7%90%86%E8%A7%84%E5%AE%9A.pdf'},
    {name: '北京基金小镇公益基金会证书管理规定', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/constitution/11-%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E8%AF%81%E4%B9%A6%E7%AE%A1%E7%90%86%E8%A7%84%E5%AE%9A.pdf'},
    {name: '北京基金小镇公益基金会志愿者管理办法', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/constitution/12-%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E5%BF%97%E6%84%BF%E8%80%85%E7%AE%A1%E7%90%86%E5%8A%9E%E6%B3%95.pdf'},
    {name: '北京基金小镇公益基金会保密工作制度', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/constitution/13-%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E4%BF%9D%E5%AF%86%E5%B7%A5%E4%BD%9C%E5%88%B6%E5%BA%A6.pdf'},
    {name: '北京基金小镇公益基金会保值增值投资活动管理办法', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/constitution/14-%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E4%BF%9D%E5%80%BC%E5%A2%9E%E5%80%BC%E6%8A%95%E8%B5%84%E6%B4%BB%E5%8A%A8%E7%AE%A1%E7%90%86%E5%8A%9E%E6%B3%95.pdf'},
    {name: '北京基金小镇公益基金会财务管理制度（修订）', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/constitution/15-%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E8%B4%A2%E5%8A%A1%E7%AE%A1%E7%90%86%E5%88%B6%E5%BA%A6%EF%BC%88%E4%BF%AE%E8%AE%A2%EF%BC%89.pdf'},
    {name: '北京基金小镇公益基金会财务人员岗位职责', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/constitution/16-%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E8%B4%A2%E5%8A%A1%E4%BA%BA%E5%91%98%E5%B2%97%E4%BD%8D%E8%81%8C%E8%B4%A3.pdf'},
    {name: '北京基金小镇公益基金会采购管理办法', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/constitution/17-%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E9%87%87%E8%B4%AD%E7%AE%A1%E7%90%86%E5%8A%9E%E6%B3%95.pdf'},
    {name: '北京基金小镇公益基金会差旅费管理办法', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/constitution/18-%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E5%B7%AE%E6%97%85%E8%B4%B9%E7%AE%A1%E7%90%86%E5%8A%9E%E6%B3%95.pdf'},
    {name: '北京基金小镇公益基金会会计档案管理办法', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/constitution/19-%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E4%BC%9A%E8%AE%A1%E6%A1%A3%E6%A1%88%E7%AE%A1%E7%90%86%E5%8A%9E%E6%B3%95.pdf'},
    {name: '北京基金小镇公益基金会会议管理制度', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/constitution/20-%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E4%BC%9A%E8%AE%AE%E7%AE%A1%E7%90%86%E5%88%B6%E5%BA%A6.pdf'},
    {name: '北京基金小镇公益基金会监事（会）工作制度', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/constitution/21-%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E7%9B%91%E4%BA%8B%EF%BC%88%E4%BC%9A%EF%BC%89%E5%B7%A5%E4%BD%9C%E5%88%B6%E5%BA%A6.pdf'},
    {name: '北京基金小镇公益基金会秘书处管理办法', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/constitution/22-%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E7%A7%98%E4%B9%A6%E5%A4%84%E7%AE%A1%E7%90%86%E5%8A%9E%E6%B3%95.pdf'},
    {name: '北京基金小镇公益基金会信用管理制度', link: 'https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/foundation/constitution/23-%E5%8C%97%E4%BA%AC%E5%9F%BA%E9%87%91%E5%B0%8F%E9%95%87%E5%85%AC%E7%9B%8A%E5%9F%BA%E9%87%91%E4%BC%9A%E4%BF%A1%E7%94%A8%E7%AE%A1%E7%90%86%E5%88%B6%E5%BA%A6.pdf'},
  ]

  return (
    <div className="foundation-info w1200">
      <Tabs defaultActiveKey="1" type="card" onChange={changeTabPane}>
        <Tabs.TabPane tab="年度报告" key="1">
          <ul className="annual-report w1200">
            {annualReport.map((item, i) => {
              return (
                <li key={i}>
                  <a href={item.link} target="_blank">
                    <img src="/image/left-triangle.png" alt="" />
                    {item.name}
                    <EyeOutlined />
                  </a>
                </li>
              );
            })}
          </ul>
        </Tabs.TabPane>
        <Tabs.TabPane tab="审计报告" key="2">
          <ul className="annual-report w1200">
            {auditReport.map((item, i) => {
              return (
                <li key={i}>
                  <a href={item.link} target="_blank">
                    <img src="/image/left-triangle.png" alt="" />
                    {item.name}
                    <EyeOutlined />
                  </a>
                </li>
              );
            })}
          </ul>
        </Tabs.TabPane>
        <Tabs.TabPane tab="公示公文" key="3">
          <ul className="annual-report w1200" style={{minHeight: '500px'}}>
            {publicOfficials.map((item, i) => {
              return (
                <li key={i}>
                  <a href={item.link} target="_blank">
                    <img src="/image/left-triangle.png" alt="" />
                    {item.name}
                    <EyeOutlined />
                  </a>
                </li>
              );
            })}
          </ul>
        </Tabs.TabPane>
        <Tabs.TabPane tab="章程制度" key="4">
          <ul className="annual-report w1200">
            {constitutionList.map((item, i) => {
              return (
                <li key={i}>
                  <a href={item.link} target="_blank">
                    <img src="/image/left-triangle.png" alt="" />
                    {item.name}
                    <EyeOutlined />
                  </a>
                </li>
              );
            })}
          </ul>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default FoundationInfo;
