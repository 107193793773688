import { Link } from "react-router-dom";
import {getActivityLists} from "../../../api/home";
import "./index.less";
import {Pagination} from "antd";
import {useEffect,useState} from "react";
function itemRender(current, type, originalElement) {
  if (type === "prev") {
    return <span>上一页</span>;
  }
  if (type === "next") {
    return <span>下一页</span>;
  }
  return originalElement;
}

const ImgItem = (props) => {
  return (
    <Link className="ImgItem" to={`/activity/${props.id}`} target="_blank" >
      <img src={props.image} alt="" />
      <div className="ImgItem-content">
        <h4>{props.name}</h4>
        <span>{props.create_time}</span>
      </div>
    </Link>
  );
};
const useDynamic = (page) => {
  const [activityList, setActivityList] = useState({ list: [], pageInfo: {} });
  useEffect(() => {
    // 获取公告数据
    getActivityLists({page}).then((res) => {
      const { code, data } = res;
      if (code === 200) setActivityList(data);
    });
  }, [page]);
  return [activityList];
};
const FoundationActivity = (props) => {
  const [page,setPage] = useState(1);
  const [dynamic] = useDynamic(page);
  return (
    <div className="activity w1200">
      {
        dynamic && dynamic.list.length > 0 ?
          (
            dynamic.list.map((item, i) => {
              return <ImgItem key={i} {...item} meta="基金会活动" />;
            })
          ):(<h3>暂无数据</h3>)
      }
      <Pagination
        total={dynamic.pageInfo.dataTotal}
        itemRender={itemRender}
        pageSize={8}
        current={dynamic.pageInfo.current}
        showSizeChanger={false}
        onChange={(page, pageSize) => {
          setPage(page);
        }}
      />
    </div>

  );
};
export default FoundationActivity;
