/*
 * @Description: 通用文章详情
 * @Autor: lichao
 * @Date: 2021-11-30 13:57:14
 * @LastEditors: lichao
 * @LastEditTime: 2022-02-15 15:07:37
 * 需要传入的props的数据
 * 路由meta信息 包含name和pathname
 * 文章的请求id
 */

import { withRouter, Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import { useState, useEffect } from "react";
import { getNewArticle } from "../../api/home";
import "./index.less";
const Article = (props) => {
  // console.log(props, "dddddddddddd");
  const [article, setArticle] = useState({});
  useEffect(() => {
    getNewArticle({ id: props.match.params.id }).then((res) => {
      //   console.log(res);
      if (res.code === 200) {
        setArticle(res.data);
        document.title=res.data.name
      }
    });
  }, [props.match.params.id]);
  let BreadcrumbPathFirst =
    props.meta.pathname.indexOf("foundation") > 0
      ? "/foundation"
      : props.meta.pathname.indexOf("news") > 0
      ? "/news"
      : props.meta.pathname.indexOf("partybuilding") > 0
      ? "/partybuilding"
      : "";
  let BreadcrumbPathFirstText =
    props.meta.pathname.indexOf("foundation") > 0
      ? "公益基金会"
      : props.meta.pathname.indexOf("news") > 0
      ? "小镇动态"
      : props.meta.pathname.indexOf("partybuilding") > 0
      ? "党建工作"
      : "";
  return (
    <div className="article-container">
      <div className="w1200 breadcrumb">
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={BreadcrumbPathFirst}>{BreadcrumbPathFirstText}</Link>
          </Breadcrumb.Item>

          {props.meta.name !== "党建工作" && (
            <Breadcrumb.Item>
              <Link to={props.meta.pathname}>{props.meta.name}</Link>
            </Breadcrumb.Item>
          )}

          <Breadcrumb.Item>{article.name}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="w1200 title">
        <h4>{article.name}</h4>
        <div>
          <span style={{ marginRight: article.source_title ? "10px" : "0px" }}>
            {article.create_time}
          </span>
          {article.source_title ? (
            <span>来源: {article.source_title}</span>
          ) : (
            ""
          )}
        </div>
      </div>

      {article.video_url ? (
        <div className="w1200 video_content" style={{ marginTop: "20px" }}>
          <div dangerouslySetInnerHTML={{ __html: article.content }} className="content"></div>

          <video src={article.video_url} controls></video>
        </div>
      ) : (
        <div
          className="w1200 content"
          dangerouslySetInnerHTML={{ __html: article.content }}
        ></div>
      )}
    </div>
  );
};
export default withRouter(Article);
