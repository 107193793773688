import { useState, useEffect } from "react";
import { Pagination } from "antd";
import TextImg from "../../../components/TextImg";
import { getNewList } from "../../../api/home";
// 切页
function itemRender(current, type, originalElement) {
  if (type === "prev") {
    return <span>上一页</span>;
  }
  if (type === "next") {
    return <span>下一页</span>;
  }
  return originalElement;
}
// 小镇新闻
const useNews = (page) => {
  const [news, setNews] = useState({ list: [], pageInfo: {} });
  useEffect(() => {
    getNewList({ location_id: 2, page }).then((res) => {
      const { code, data } = res;
      if (code === 200) setNews(data);
    });
  }, [page]);
  return [news];
};

const Focus = (props) => {
  // 小镇要闻
  const [newsPage, setNewsPage] = useState(1);
  const [news] = useNews(newsPage);
  return (
    <div className="information-container w1200">
      {news.list.map((n, ni) => {
        return <TextImg key={ni} {...n}  meta={props.meta}  pathname="/focus"/>;
      })}
      <Pagination
        total={news.pageInfo.dataTotal}
        itemRender={itemRender}
        pageSize={8}
        current={news.pageInfo.current}
        showSizeChanger={false}
        onChange={(page, pageSize) => {
          setNewsPage(page);
        }}
      />
    </div>
  );
};

export default Focus;
