/*
 * @Description:首页
 * @Autor: lichao
 * @Date: 2021-11-26 09:54:59
 * @LastEditors: 双子座cactus 10177246+cactus_wang@user.noreply.gitee.com
 * @LastEditTime: 2023-04-28 17:52:10
 */
import {useState, useEffect, useRef} from "react";
import Banner from "../../components/Banner";
import {getHome, getadlist, getNumber} from "../../api/home";
import {Carousel} from "antd";
// 导入基本swipe组件
import {Swiper, SwiperSlide} from "swiper/react";
// 导入自动播放和分页器模块
import SwiperCore, {Pagination, Autoplay, Navigation} from "swiper";
// 导入swiper组件样式
import "swiper/swiper.less";
import "swiper/components/pagination/pagination.less";
import "./index.less";

SwiperCore.use([Pagination, Autoplay, Navigation]);

const useNum = () => {
  const [num, setNum] = useState({
    management_assets: 1683,
    institutions_number: 1824,
  });
  useEffect(() => {
    getNumber().then((res) => {
      const {code, data} = res;
      if (code === 200)
        setNum({
          management_assets: data.now_information_data[0].management_assets,
          institutions_number: data.now_information_data[0].institutions_number,
        });
    });
  }, []);
  return [num];
};

const HomeAbout = (props) => {
  const [num] = useNum();
  const {about} = props;
  const mechanism_doms = useRef();
  let settledNumber = num.institutions_number.toString().split("");
  let capitalNumber = num.management_assets.toString().split("");
  // 入驻数据
  const num1 = settledNumber[0];
  const num2 = settledNumber[1];
  const num3 = settledNumber[2];
  const num4 = settledNumber[3];
  // 资本数据
  const num5 = capitalNumber[0];
  const num6 = capitalNumber[1];
  const num7 = capitalNumber[2];
  const num8 = capitalNumber[3];
  return (
    <div className="home-about w1200">
      {/* <div className="float-num" style={{
        background: `url(../image/new_year_left.png) no-repeat`,
      }}>*/}
      <div className="float-num">
        <div className="float-num-item">
          <h4>服务基金机构</h4>
          <div className="number-container">
            <ul className="mechanism" ref={mechanism_doms}>
              <li className="mechanism-item">
                <div style={{transform: `translateY(-${num1 * 65}px)`}}>
                  <span>0</span>
                  <span>1</span>
                  <span>2</span>
                  <span>3</span>
                  <span>4</span>
                  <span>5</span>
                  <span>6</span>
                  <span>7</span>
                  <span>8</span>
                  <span>9</span>
                </div>
              </li>
              <li className="mechanism-item">
                <div style={{transform: `translateY(-${num2 * 65}px)`}}>
                  <span>0</span>
                  <span>1</span>
                  <span>2</span>
                  <span>3</span>
                  <span>4</span>
                  <span>5</span>
                  <span>6</span>
                  <span>7</span>
                  <span>8</span>
                  <span>9</span>
                </div>
              </li>
              <li className="mechanism-item">
                <div style={{transform: `translateY(-${num3 * 65}px)`}}>
                  <span>0</span>
                  <span>1</span>
                  <span>2</span>
                  <span>3</span>
                  <span>4</span>
                  <span>5</span>
                  <span>6</span>
                  <span>7</span>
                  <span>8</span>
                  <span>9</span>
                </div>
              </li>
              <li className="mechanism-item">
                <div style={{transform: `translateY(-${num4 * 65}px)`}}>
                  <span>0</span>
                  <span>1</span>
                  <span>2</span>
                  <span>3</span>
                  <span>4</span>
                  <span>5</span>
                  <span>6</span>
                  <span>7</span>
                  <span>8</span>
                  <span>9</span>
                </div>
              </li>
            </ul>
            <span>家</span>
          </div>
        </div>
        <div className="float-num-item">
          <h4>注册资本</h4>
          <div className="number-container">
            <ul className="mechanism" ref={mechanism_doms}>
              <li className="mechanism-item">
                <div style={{transform: `translateY(-${num5 * 65}px)`}}>
                  <span>0</span>
                  <span>1</span>
                  <span>2</span>
                  <span>3</span>
                  <span>4</span>
                  <span>5</span>
                  <span>6</span>
                  <span>7</span>
                  <span>8</span>
                  <span>9</span>
                </div>
              </li>
              <li className="mechanism-item">
                <div style={{transform: `translateY(-${num6 * 65}px)`}}>
                  <span>0</span>
                  <span>1</span>
                  <span>2</span>
                  <span>3</span>
                  <span>4</span>
                  <span>5</span>
                  <span>6</span>
                  <span>7</span>
                  <span>8</span>
                  <span>9</span>
                </div>
              </li>
              <li className="mechanism-item">
                <div style={{transform: `translateY(-${num7 * 65}px)`}}>
                  <span>0</span>
                  <span>1</span>
                  <span>2</span>
                  <span>3</span>
                  <span>4</span>
                  <span>5</span>
                  <span>6</span>
                  <span>7</span>
                  <span>8</span>
                  <span>9</span>
                </div>
              </li>
              <li className="mechanism-item">
                <div style={{transform: `translateY(-${num8 * 65}px)`}}>
                  <span>0</span>
                  <span>1</span>
                  <span>2</span>
                  <span>3</span>
                  <span>4</span>
                  <span>5</span>
                  <span>6</span>
                  <span>7</span>
                  <span>8</span>
                  <span>9</span>
                </div>
              </li>
            </ul>
            <span>亿元</span>
          </div>
        </div>
        {/* <span className="time">（截至2021年12月）</span> */}
      </div>
      <div className="float-button">
        <a
          href="https://apply.beijingfundtown.com/"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/image/float-btn-1.png" alt=""/>
        </a>
        <a
          href="http://toujiao.beijingfundtown.com/home"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/image/float-btn-2.png" alt=""/>
        </a>
        <a
          href="https://www.beijingfundtown.com"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/image/float-btn-3.png" alt=""/>
        </a>
      </div>
      {/*春节*/}
      {/*<div className="float-button" style={{background:'url(../image/new_year_right.png) no-repeat'}}>
        <div className="float-button-container">
          <a
            href="https://apply.beijingfundtown.com/"
            target="_blank"
            rel="noreferrer"
          >
            一键入驻小镇
          </a>
          <a
            href="http://toujiao.beijingfundtown.com/home"
            target="_blank"
            rel="noreferrer"
          >
            国家级投教基地
          </a>
          <a
            href="https://www.beijingfundtown.com"
            target="_blank"
            rel="noreferrer"
          >
            云端·北京基金小镇
          </a>
        </div>

      </div>*/}
      {/*改成轮播图，whh  后续改图由前端替换图片 2023年4月27日14:44:09*/}
      {/*<img src={about && about.text_image} alt="" />*/}
      <div className="carousel-content">
        <Carousel autoplay autoplaySpeed={2500}>
          <img src="/image/about_our1.png" alt=""/>
          <img src="/image/about_our2.png" alt=""/>
        </Carousel>
      </div>
      <div style={{flex: '1'}}>
        <h4>关于我们</h4>
        {/*因为识别不出自然段，先暂时手写文本分段，后续待定 whh 2023年4月27日14:44:14*/}
        <p>北京基金小镇以“创新、协调、绿色、开放、共享”新发展理念为引领，打造国家级基金业专业服务平台，探索形成“1+3+N”专业服务体系，支持基金业安全发展，更好服务实体经济，推动科技创新及现代化产业体系建设，助力首都高质量发展。</p>
        {/*<p>{about ? about.content : ""}</p>*/}
      </div>
    </div>
  );
};

const HomePosition = (props) => {
  const {position} = props;
  const [activeKey, setActiveKey] = useState(0);
  return (
    position &&
    position.length && (
      <div className="home-position-box w1200">
        <div className="home-position">
          {position.map((item, index) => {
            return (
              <div
                key={index}
                className="home-position-item"
                onClick={() => setActiveKey(index)}
                style={{
                  background: `url(${item.cover_img}) no-repeat left top`,
                  backgroundSize:'contain'
                }}
              ></div>
            );
          })}
        </div>

        <div className="home-position-content">
          <h4>{position[activeKey].title}</h4>
          {
            showContent(position[activeKey].title,position[activeKey].content,position[activeKey].text_image)
          }
        </div>
      </div>
    )
  );
};
const showContent = (title,content,text_image) => {
  if(title === '云端·北京基金小镇'){
    return <div className="content">
      <p>{content}</p>
      <div className="carousel-content">
        <Carousel autoplay autoplaySpeed={2500}>
          <img src="/image/fundtown(1).jpg" alt=""/>
          <img src="/image/fundtown(2).png" alt=""/>
          <img src="/image/fundtown(3).png" alt=""/>
          <img src="/image/fundtown(4).jpg" alt=""/>
          <img src="/image/fundtown(5).png" alt=""/>
          <img src="/image/fundtown(6).png" alt=""/>
        </Carousel>
      </div>
    </div>
  }
  else if(title === '全周期基金业专业服务'){
    return  <div className="content">
      <p>{content}</p>
      <div className="carousel-content">
        <Carousel autoplay autoplaySpeed={2500}>
          <img src="/image/zyfw1.jpg" alt=""/>
          <img src="/image/zyfw2.jpg" alt=""/>
          <img src="/image/zyfw3.jpg" alt=""/>
        </Carousel>
      </div>
    </div>
  }
  else if (title === '全产业链战略合作平台'){
    return <div className="content">
      <p>{content}</p>
      <div className="carousel-content">
        <Carousel autoplay autoplaySpeed={2500}>
          <img src="/image/services(1).jpg" alt=""/>
          <img src="/image/services(2).jpg" alt=""/>
          <img src="/image/services(3).jpg" alt=""/>
        </Carousel>
      </div>
    </div>
  }
  else if(title === '国家级投资者教育基地'){
    return <div className="content">
      <p>
        <span>北京基金小镇投资者教育基地是中国首家以基金业投资者和管理人教育为主题的投资者教育基地，依托基金业数字化服务平台，构建全媒体投教宣传矩阵，以“线上线下联动”的方式，深耕私募基金领域的投资者教育，深入开展符合私募行业特点的投资者保护工作，引导管理人诚信合规展业，培育成熟理性的投资者，倡导投资者保护，促进基金业诚信健康发展。</span>
        <span style={{display: 'block'}}>2019年，获中国证监会北京监管局命名授牌，成为“北京辖区证券期货投资者教育基地”。</span>
        <span style={{display: 'block'}}>2021年，获中国证监会命名授牌，成为“全国证券期货投资者教育基地”。</span>
      </p>
      <div className="carousel-content">
        <Carousel autoplay autoplaySpeed={2500}>
          <img src="/image/tzjy1.jpg" alt=""/>
          <img src="/image/tzjy2.jpg" alt=""/>
        </Carousel>
      </div>
    </div>
  }
  else if (title === '国家级基金业服务标准化示范'){
    return <div className="content">
      <p>
        <span>以高标准引领高质量发展，北京基金小镇作为唯一国家级基金业服务标准化示范，聚焦“最优标准服务基金机构”“有效防范化解金融风险”“维护投资者合法权益”等核心目标，向全国、全行业输出可复制、可推广的经验，助力提升我国基金业综合服务水平。</span>
        <span style={{display: 'block'}}>2019年，经国家标准委批复，成为国家级基金业服务标准化试点。</span>
        <span style={{display: 'block'}}>2022年，经国家标准委评审，启动建设国家级基金业服务标准化示范。</span>
        <span style={{display: 'block'}}>2023年，国家市场监管总局、国家标准委发布公告，由中国标准化研究院、北京基金小镇等单位主导起草的两项国家金融标准发布实施。</span>
      </p>
      <img src={text_image} alt=""/>
    </div>
  }
  else if(title === '北京基金小镇研究院'){
    return <div className="content">
      <p>
        <span> 为防范金融风险，构建良好的私募基金行业生态，在中国证监会投资者保护局、中国证监会北京监管局、中国证券投资基金业协会的指导下，北京基金小镇发起成立北京基金小镇研究院，联合中央财经大学、中国政法大学等高校、知名律师事务所、会计师事务所等专业服务机构及头部投资机构,共同开展金融领域的相关研究。</span>
        <span style={{display: 'block'}}>北京基金小镇研究院围绕金融形势和政策、金融市场和机构、金融体系和风险等方面，以政策研究、财富管理、金融风险防范及化解，以及各垂直领域的行业研究为重点研究方向，致力于构筑集高水平科学研究、高层次人才培养为一体的创新型智库。</span>
      </p>
      <img src={text_image} alt=""/>
    </div>
  }
  else if (title === '多点布局 跨区域协同发展'){
    return <div className="content">
      <p>{content}</p>
      <div className="carousel-content">
        <Carousel autoplay autoplaySpeed={2500}>
          <img src="/image/center1.png" alt=""/>
          <img src="/image/center2.png" alt=""/>
          <img src="/image/center3.png" alt=""/>
        </Carousel>
      </div>
    </div>
  }
  else if(title === '集聚专业智库  共建国家级投教基地——投教基地APP'){
    return  <div className="content">
      <p>{content}</p>
      <div className="carousel-content">
        <Carousel autoplay autoplaySpeed={2500}>
          <img src="https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/app1.jpg" alt=""/>
          <img src="https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/app2.jpg" alt=""/>
          <img src="https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/app4.jpg" alt=""/>
        </Carousel>
      </div>
    </div>
  }
  else if (title === '领导关怀'){
    return  <div className="content">
      <p>{content}</p>
      <div className="carousel-content">
        <Carousel autoplay autoplaySpeed={2500}>
          <img src="https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/leader1.jpg" alt=""/>
          <img src="https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/leader2.jpg" alt=""/>
          <img src="https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/leader3.jpg" alt=""/>
          <img src="https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/leader4.jpg" alt=""/>
          <img src="https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/leader5.jpg" alt=""/>
          <img src="https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/leader6.jpg" alt=""/>
          <img src="https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/leader7.jpg" alt=""/>
          <img src="https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/leader8.jpg" alt=""/>
          <img src="https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/leader9.jpg" alt=""/>
          <img src="https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/leader10.jpg" alt=""/>
          <img src="https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/leader11.jpg" alt=""/>
          <img src="https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/leader12.jpg" alt=""/>
          <img src="https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/leader13.jpg" alt=""/>
          <img src="https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/leader14.jpg" alt=""/>
          <img src="https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/leader15.jpg" alt=""/>
          <img src="https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/town_introduction/leader16.jpg" alt=""/>
        </Carousel>
      </div>
    </div>
  }
  else{
    return <div className="content">
      <p>{content}</p>
      <img src={text_image} alt=""/>
    </div>
  }

}
const HomeVideo = (props) => {
  const {videolist} = props;

  const [activeIndex, setActiveIndex] = useState(0);
  console.log(activeIndex);
  let onSlideChange = (swp) => {
    const {activeIndex} = swp;
    setActiveIndex(activeIndex);
  };

  let onSwiper = (swp) => {
    const {activeIndex} = swp;
    setActiveIndex(activeIndex);
  };

  return (
    <div className="home-video-swiper w1200">
      <div className="arrow">
        <div className="swiper-button-prev">
          <img src="/image/homeVideoLeft.png" alt=""/>
        </div>
      </div>

      <Swiper
        slidesPerView={2} //同屏显示多少个swiper滑块
        initialSlide={0} //默认展示第几个滑块
        spaceBetween={10}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          disabledClass: "disable", // 当导航按钮变为不可用时添加的class,也就是当swiper索引为0时上一张没有prevEl的class类名就会添加一个disable，也就是.swiper-button-prev .disable
        }}
        // loop={true} //是否开启无限轮播
        // pagination={{ clickable: true }} //开启分页器操作
        observer={true} //修改swiper自己或子元素时，自动初始化swiper(如果数据是请求下来的一定要重新初始化)
        observeParents={true} //修改swiper的父元素时，自动初始化swiper(如果数据是请求下来的一定要重新初始化)
        autoplay={false} //自动轮播开启
        onSlideChange={onSlideChange}
        onSwiper={onSwiper}
      >
        {videolist.length &&
          videolist.map((v, i) => (
            <SwiperSlide key={i}>
              <div className="swiper-item">
                <video src={v.video_url} poster={v.cover_img} controls></video>
                <h4>{v.title}</h4>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
      <div className="arrow">
        <div className="swiper-button-next">
          <img src="/image/homeVideoLeft.png" alt=""/>
        </div>
      </div>
    </div>
  );
};

const Home = () => {
  const [home, setHome] = useState({
    index_one: [],
    index_two: [],
    index_three: [],
  });
  const [bannerlist, setbannerlist] = useState([]);

  useEffect(() => {
    getHome().then((res) => {
      const {code, data} = res;
      // console.log(res);
      if (code === 200) {
        setHome({...data});
      }
    });
    getadlist({position_id: 4}).then((res) => {
      const {
        code,
        data: {banner_list},
      } = res;
      if (code === 200) {
        setbannerlist(banner_list);
      }
    });
  }, []);
  return (
    <div className="home-container">
      <Banner bannerOptions={bannerlist} hasArrow={true}/>
      <HomeAbout about={home.index_one[0]}/>
      <HomePosition position={home.index_two}/>
      <HomeVideo videolist={home.index_three}/>
    </div>
  );
};

export default Home;

// const positionOptions = [
//   {
//     name: "首都金融",
//     content:
//       "北京，国家金融管理中心所在地，全国唯一的“服务业扩大开放综合示范区和自由贸易试验区”。北京基金小镇作为连续列入北京市“十三五”“十四五”规划、北京市推进京津冀协同发展的重点项目，着力打造北京金融“微中心”，与金融街、CBD、金融科技与专业服务创新示范区、国家文化与金融合作示范区等金融集聚区“多点化”布局，构造首都金融战略发展新布局，助力首都“两区”建设，成为面向全球金融开放的最前沿。 北京，国家金融管理中心所在地，全国唯一的“服务业扩大开放综合示范区和自由贸易试验区”。北京基金小镇作为连续列入北京市“十三五”“十四五”规划、北京市推进京津冀协同发展的重点项目，着力打造北京金融“微中心”，与金融街、CBD、金融科技与专业服务创新示范区、国家文化与金融合作示范区等金融集聚区“多点化”布局，构造首都金融战略发展新布局，助力首都“两区”建设，成为面向全球金融开放的最前沿。",
//     img: "",
//   },
//   {
//     name: "基金生态",
//     content:
//       "北京，国家金融管理中心所在地，全国唯一的“服务业扩大开放综合示范区和自由贸易试验区”。北京基金小镇作为连续列入北京市“十三五”“十四五”规划、北京市推进京津冀协同发展的重点项目，着力打造北京金融“微中心”，与金融街、CBD、金融科技与专业服务创新示范区、国家文化与金融合作示范区等金融集聚区“多点化”布局，构造首都金融战略发展新布局，助力首都“两区”建设，成为面向全球金融开放的最前沿。 北京，国家金融管理中心所在地，全国唯一的“服务业扩大开放综合示范区和自由贸易试验区”。北京基金小镇作为连续列入北京市“十三五”“十四五”规划、北京市推进京津冀协同发展的重点项目，着力打造北京金融“微中心”，与金融街、CBD、金融科技与专业服务创新示范区、国家文化与金融合作示范区等金融集聚区“多点化”布局，构造首都金融战略发展新布局，助力首都“两区”建设，成为面向全球金融开放的最前沿。",
//     img: "",
//   },
//   {
//     name: "专业服务",
//     content:
//       "北京，国家金融管理中心所在地，全国唯一的“服务业扩大开放综合示范区和自由贸易试验区”。北京基金小镇作为连续列入北京市“十三五”“十四五”规划、北京市推进京津冀协同发展的重点项目，着力打造北京金融“微中心”，与金融街、CBD、金融科技与专业服务创新示范区、国家文化与金融合作示范区等金融集聚区“多点化”布局，构造首都金融战略发展新布局，助力首都“两区”建设，成为面向全球金融开放的最前沿。 北京，国家金融管理中心所在地，全国唯一的“服务业扩大开放综合示范区和自由贸易试验区”。北京基金小镇作为连续列入北京市“十三五”“十四五”规划、北京市推进京津冀协同发展的重点项目，着力打造北京金融“微中心”，与金融街、CBD、金融科技与专业服务创新示范区、国家文化与金融合作示范区等金融集聚区“多点化”布局，构造首都金融战略发展新布局，助力首都“两区”建设，成为面向全球金融开放的最前沿。",
//     img: "",
//   },
//   {
//     name: "专属家园",
//     content:
//       "北京，国家金融管理中心所在地，全国唯一的“服务业扩大开放综合示范区和自由贸易试验区”。北京基金小镇作为连续列入北京市“十三五”“十四五”规划、北京市推进京津冀协同发展的重点项目，着力打造北京金融“微中心”，与金融街、CBD、金融科技与专业服务创新示范区、国家文化与金融合作示范区等金融集聚区“多点化”布局，构造首都金融战略发展新布局，助力首都“两区”建设，成为面向全球金融开放的最前沿。 北京，国家金融管理中心所在地，全国唯一的“服务业扩大开放综合示范区和自由贸易试验区”。北京基金小镇作为连续列入北京市“十三五”“十四五”规划、北京市推进京津冀协同发展的重点项目，着力打造北京金融“微中心”，与金融街、CBD、金融科技与专业服务创新示范区、国家文化与金融合作示范区等金融集聚区“多点化”布局，构造首都金融战略发展新布局，助力首都“两区”建设，成为面向全球金融开放的最前沿。",
//     img: "",
//   },
//   {
//     name: "诚信安全",
//     content:
//       "北京，国家金融管理中心所在地，全国唯一的“服务业扩大开放综合示范区和自由贸易试验区”。北京基金小镇作为连续列入北京市“十三五”“十四五”规划、北京市推进京津冀协同发展的重点项目，着力打造北京金融“微中心”，与金融街、CBD、金融科技与专业服务创新示范区、国家文化与金融合作示范区等金融集聚区“多点化”布局，构造首都金融战略发展新布局，助力首都“两区”建设，成为面向全球金融开放的最前沿。 北京，国家金融管理中心所在地，全国唯一的“服务业扩大开放综合示范区和自由贸易试验区”。北京基金小镇作为连续列入北京市“十三五”“十四五”规划、北京市推进京津冀协同发展的重点项目，着力打造北京金融“微中心”，与金融街、CBD、金融科技与专业服务创新示范区、国家文化与金融合作示范区等金融集聚区“多点化”布局，构造首都金融战略发展新布局，助力首都“两区”建设，成为面向全球金融开放的最前沿。",
//     img: "",
//   },
//   {
//     name: "国家标准",
//     content:
//       "北京，国家金融管理中心所在地，全国唯一的“服务业扩大开放综合示范区和自由贸易试验区”。北京基金小镇作为连续列入北京市“十三五”“十四五”规划、北京市推进京津冀协同发展的重点项目，着力打造北京金融“微中心”，与金融街、CBD、金融科技与专业服务创新示范区、国家文化与金融合作示范区等金融集聚区“多点化”布局，构造首都金融战略发展新布局，助力首都“两区”建设，成为面向全球金融开放的最前沿。 北京，国家金融管理中心所在地，全国唯一的“服务业扩大开放综合示范区和自由贸易试验区”。北京基金小镇作为连续列入北京市“十三五”“十四五”规划、北京市推进京津冀协同发展的重点项目，着力打造北京金融“微中心”，与金融街、CBD、金融科技与专业服务创新示范区、国家文化与金融合作示范区等金融集聚区“多点化”布局，构造首都金融战略发展新布局，助力首都“两区”建设，成为面向全球金融开放的最前沿。",
//     img: "",
//   },
// ];
