/*
 * @Description: 加入我们
 */

import { useState, useRef } from "react";
import { submitForm } from "../../../api/home";
import { Button, Input, Form, Radio, Space, message } from "antd";
import QS from "querystring";

import "./index.less";
const FoundationContact = () => {
  // 表单相关
  const _Form = useRef();
  const [value, setValue] = useState(null);
  const onFinish = (values) => {
    let v = QS.stringify(values);
    submitForm(v).then((res) => {
      let { code, msg } = res;
      if (code === 200) {
        message.info("提交成功");
        // 清空表单的值
        _Form.current.resetFields();
      } else {
        message.info(msg);
      }
      console.log(res);
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };
  return (
    <div>
      <div className="contact-content" style={{textAlign:"center"}}>
        <h4 className="contact-title">联系方式</h4>
        <span className="contact-txt">机构电话：010-56860668-213（座机）、13911803357（手机）</span>
        <span className="contact-txt">联系人：刘英杰</span>
        <span className="contact-txt">电子信箱：foundation@bjfundtown.com</span>
        <span className="contact-txt">微信二维码：您可扫描以下二维码对接我们的工作人员</span>
        <span className="contact-code">
          <img src="/image/code.png" alt=""/>
        </span>

      </div>
      <div className="contact-content">
        <h4 className="contact-title">同时期待您的加入</h4>
        <Form
          name="basic"
          labelCol={{ span: 9 }}
          wrapperCol={{ span: 8 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          ref={_Form}
        >
          <Form.Item
            label="您的称呼"
            name="name"
            rules={[{ required: true, message: "请输入您的称呼!" }]}
          >
            <Input size="large" />
          </Form.Item>

          <Form.Item
            label="您的所在单位名称："
            name="company_name"
            rules={[{ required: true, message: "请输入您的所在单位名称!" }]}
          >
            <Input size="large" />
          </Form.Item>

          <Form.Item
            label="您的联系电话："
            name="mobile"
            rules={[{ required: true, message: "请输入您的联系电话!" }]}
          >
            <Input size="large" />
          </Form.Item>

          <Form.Item label="您的邮箱：" name="email">
            <Input size="large" />
          </Form.Item>

          <Form.Item
            label="您的意愿："
            name="wish"
            rules={[{ required: true, message: "请输入您的意愿!" }]}
          >
            <Radio.Group onChange={onChange} value={value}>
              <Space direction="vertical">
                <Radio value={1}>与小镇公益基金会成为合作伙伴</Radio>
                <Radio value={2}>加入我们，成为志愿者</Radio>
                <Radio value={3}>捐款/捐物/捐赠等</Radio>
                <Radio value={4}>公益项目合作</Radio>
                <Radio value={5}>
                  其他
                  {value === 5 ? (
                    <Form.Item name="other">
                      <Input />
                    </Form.Item>
                  ) : null}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            wrapperCol={{ offset: 0, span: 24 }}
            className="button-content"
          >
            <Button htmlType="submit">提交</Button>
          </Form.Item>
          <p className="tips">
            我们的工作人员将第一时间与您取得联系，请保障您的电话的畅通，感谢您对北京基金小镇公益基金会的支持！
            希望与您一起开拓公益事业。
          </p>
        </Form>
      </div>
    </div>

  );
};

export default FoundationContact;
