import { Link } from "react-router-dom";
import "./index.less";
const ImgItem = (props) => {
  return (
    <Link className="ImgItem" to={`/partybuilding/${props.id}`}>
      <img src={props.image} alt="" />
      <div className="ImgItem-content">
        <h4>{props.name}</h4>
        <span>{props.create_time}</span>
      </div>
    </Link>
  );
};

export default ImgItem;
