import { Swiper, SwiperSlide } from "swiper/react";
// 导入自动播放和分页器模块
import SwiperCore, { Pagination, Autoplay, Navigation } from "swiper";
// 导入swiper组件样式
import "swiper/swiper.less";
import "swiper/components/pagination/pagination.less";
import "./index.less";

SwiperCore.use([Pagination, Autoplay, Navigation]);

const Demo = () => {
  let onProgress = (swp, progress) => {
    // 轮播的DOM数组
    let slides = swp.slides;
    // 遍历轮播DOM数组
    for (let i = 0; i < slides.length; i++) {
      // 当前项
      let currentSlide = slides[i];
      // 当前项进度
      let currentSlideProgress = slides[i].progress;

      let modify = 1;

      if (Math.abs(currentSlideProgress) > 1) {
        modify = (Math.abs(currentSlideProgress) - 1) * 0.3 + 1;
      }
      let translate = currentSlideProgress * modify * 260 + "px";
      let scale = 1 - Math.abs(currentSlideProgress) / 8;
      let zIndex = 999 - Math.abs(Math.round(10 * currentSlideProgress));

      currentSlide.style.transform =
        "translateX(" + translate + ") scale(" + scale + ")";
      currentSlide.style.zIndex = zIndex;
      currentSlide.style.opacity = 1;
      if (Math.abs(currentSlideProgress) > 1) {
        currentSlide.style.opacity = 0;
      }
    }
  };
  let onSetTransition = (swp, transition) => {
    let slides = swp.slides;
    for (let i = 0; i < slides.length; i++) {
      let slide = slides.eq(i);
      slide.transition(transition);
    }
  };
  return (
    <div className="demo">
      <Swiper
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          disabledClass: "disable",
        }}
        watchSlidesProgress
        centeredSlides={true}
        slidesPerView="auto"
        loopedSlides="3"
        initialSlide={1}
        loop={true}
        spaceBetween={10}
        observer={true}
        observeParents={true}
        autoplay={false}
        slideToClickedSlide
        // onSlideChange={onSlideChange}
        onProgress={onProgress}
        onSetTransition={onSetTransition}
      >
        <SwiperSlide>
          <div className="swiper-item">1</div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-item">2</div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-item">3</div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-item">3</div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-item">3</div>
        </SwiperSlide>
      </Swiper>
      {/* 左箭头 */}
      {/* <div className="swiper-button-prev">
        <div className="arrow-left">
          <img src="/image/homeVideoLeft.png" alt="" />
        </div>
      </div> */}
      {/* 右箭头 */}
      {/* <div className="swiper-button-next">
        <div className="arrow-right">
          <img src="/image/homeVideoLeft.png" alt="" />
        </div>
      </div> */}
    </div>
  );
};

export default Demo;
