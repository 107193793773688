/*
 * @Description: axios配置
 * @Autor: lichao
 * @Date: 2021-11-26 09:24:56
 * @LastEditors: lichao
 * @LastEditTime: 2022-01-27 16:47:09
 */
import axios from "axios";
import { BASEURL as baseURL, TIMEOUT as timeout } from "./config";
import MD5 from "blueimp-md5";
const instance = axios.create({ baseURL, timeout });

instance.interceptors.request.use(
  (config) => {
    // 修改salt

    let time = Date.now();
    let salt = "666";
    config.headers["fundtown-salt"] = salt;
    config.headers["fundtown-time"] = time;
    config.headers["fundtown-password"] = MD5("jjxz.com" + salt + time);
    return config;
  },
  (error) => {
    console.log(error, "request error");
  }
);

instance.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (error) => {
    return error;
  }
);

export default instance;
