/*
 * @Description: 项目动态
 * @Autor: lichao
 * @Date: 2021-12-02 11:55:00
 * @LastEditors: lichao
 * @LastEditTime: 2022-02-08 10:37:40
 */

import { useState, useCallback, useEffect } from "react";
import { getadlist, getdataLists } from "../../../api/home";
import {Pagination, Tabs} from "antd";
import TextImg from "../../../components/TextImg";
import { Swiper, SwiperSlide } from "swiper/react";
// 导入自动播放和分页器模块
import SwiperCore, {
  Pagination as swpPagination,
  Autoplay,
  Navigation,
} from "swiper";
// 导入swiper组件样式
import "swiper/swiper.less";
import "swiper/components/pagination/pagination.less";
import "./index.less";

SwiperCore.use([swpPagination, Autoplay, Navigation]);
// 公益项目列表
const items = [
  {
    id: '19',
    label: '优秀学子及人才培养',
  },
  {
    id: '20',
    label: '困境未成年人帮扶',
  },
  {
    id: '21',
    label: '应急救灾/助困帮扶',
  },{
    id: '22',
    label: '基金会资讯',
  },
];
// 切页
function itemRender(current, type, originalElement) {
  if (type === "prev") {
    return <span>上一页</span>;
  }
  if (type === "next") {
    return <span>下一页</span>;
  }
  return originalElement;
}
const useDynamic = (page,id) => {
  const [dynamic, setDynamic] = useState({ list: [], pageInfo: {} });
  useEffect(() => {
    // 获取公告数据
    getdataLists({page:page,location_id:id}).then((res) => {
      const { code, data } = res;
      if (code === 200) setDynamic(data);
    });
  }, [page,id]);
  return [dynamic];
};
const CustomTabPane = ({ tab, children, ...restProps }) => {
  return (
    <Tabs.TabPane tab={tab} {...restProps}>
      {children}
    </Tabs.TabPane>
  );
};

const FoundationProject = () => {
  // 选中项
  const [activeIndex,setActiveIndex] = useState(0);

  const [page,setPage] = useState(1);
  const [locationId,setLocationId] = useState(19)

  //const [locationId,setLocationId] =useState(19)

  const [dynamic] = useDynamic(page,locationId);
  // 轮播
  const [bannerlist, setbannerlist] = useState([]);
  // 副作
  useEffect(() => {
    getadlist({ position_id: 10 }).then((res) => {
      const {
        code,
        data: { banner_list },
      } = res;
      if (code === 200) {
        setbannerlist(banner_list);
      }
    });
  }, []);
  // 轮播图项选中事件
  let onSlideChange = useCallback(
    (swp) => {
      const { activeIndex: swpActiveIndex } = swp;
      console.log(activeIndex)
      setActiveIndex(swpActiveIndex);
    },
    [setActiveIndex,activeIndex]
  );
  // change tab Pane
  let changeTabPane = (val) => {
    setPage(1)
    setLocationId(val)
  }

  return (
    <div className="foundation-project w1200">
      <div className="welfare-project">
        <div className="welfare-title">
          <span
            style={{ background: "url(/image/welfare-title-bg.png) no-repeat" }}
          >
            公益项目及活动
          </span>
        </div>
        <div className="welfare-content">
          <Swiper
            slidesPerView={3}
            initialSlide={0}
            spaceBetween={10}
            observer={true}
            observeParents={true}
            autoplay={false}
            pagination={{ el: ".swiper-pagination" }}
            onSlideChange={onSlideChange}
            slideToClickedSlide
            effect="coverflow"
          >
            {bannerlist.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <a href={item.url?item.url:""} className="swiper-item">
                    <img src={item.text_image} alt="" />
                  </a>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className="swiper-pagination"></div>
        </div>
      </div>



      <div className="welfare-dt">
        <div>
          <Tabs defaultActiveKey="1" type="card" onChange={changeTabPane}>
            {
              items.map((items, index) => {
                return (
                  <CustomTabPane tab={items.label} key={items.id}>
                    <div className="welfart-dt-content">
                      {dynamic.list.map((dy, dyi) => {
                        return (
                          <TextImg {...dy} meta='公益动态' pathname="/project" key={dyi}/>
                        );
                      })}
                      <Pagination
                        total={dynamic.pageInfo.dataTotal}
                        itemRender={itemRender}
                        pageSize={8}
                        current={dynamic.pageInfo.current}
                        showSizeChanger={false}
                        onChange={(page, pageSize) => {
                          setPage(page);
                        }}
                      />
                    </div>
                  </CustomTabPane>
                )
              })
            }


          </Tabs>
        </div>

      </div>
    </div>
  );
};

export default FoundationProject;
