/*
 * @Description: 路由配置
 * @Autor: lichao
 * @Date: 2021-11-26 09:50:15
 * @LastEditors: lichao
 * @LastEditTime: 2021-12-17 16:25:09
 */
import Home from "../view/Home";
import Memorabilia from "../view/Memorabilia";
import Sharecenter from "../view/Sharecenter";
import Foundation from "../view/Foundation";
import Partybuilding from "../view/Partybuilding";
import News from "../view/News";
import Article from "../view/Article";
import Demo from "../view/Demo";
const routes = [
  { path: "/home", component: Home, exact: true, meta: { name: "北京基金小镇-小镇介绍" } },
  { path: "/demo", component: Demo, exact: true, meta: { name: "demo" } },
  {
    path: "/foundation",
    component: Foundation,
    exact: false,
    meta: { name: "北京基金小镇公益基金会" },
  },
  {
    path: "/foundation/introduce",
    component: Foundation,
    exact: false,
    meta: { name: "基金会简介" },
  },
  {
    path: "/foundation/project",
    focus: Foundation,
    exact: true,
    meta: { name: "项目及活动" },
  },
  {
    path: "/project/:id",
    component: Article,
    exact: true,
    meta: { name: "项目及活动", pathname: "/foundation/project" },
  },
  {
    path: "/foundation/activity",
    focus: Foundation,
    exact: true,
    meta: { name: "党建工作" },
  },
  {
    path: "/activity/:id",
    component: Article,
    exact: true,
    meta: { name: "党建工作", pathname: "/foundation/activity" },
  },
  {
    path: "/foundation/contact",
    focus: Foundation,
    exact: true,
    meta: { name: "关于我们" },
  },
  {
    path: "/foundation/info",
    component: Foundation,
    exact: true,
    meta: { name: "信息公开" },
  },
  {
    path: "/info/:id",
    component: Article,
    exact: true,
    meta: { pathname: "/foundation/info", name: "信息公开" },
  },
  {
    path: "/partybuilding/:id",
    component: Article,
    exact: true,
    meta: { pathname: "/partybuilding", name: "党建工作" },
  },
  {
    path: "/partybuilding",
    component: Partybuilding,
    meta: { name: "北京基金小镇-党建工作" },
    exact: false,
  },
  {
    path: "/memorabilia",
    component: Memorabilia,
    meta: { name: "北京基金小镇-发展历程" },
    exact: true,
  },
  {
    path: "/sharecenter",
    component: Sharecenter,
    meta: { name: "北京基金小镇-共享中心" },
    exact: true,
  },
  // 小镇动态系
  { path: "/news", component: News, exact: false, meta: { name: "北京基金小镇-小镇动态" } },
  {
    path: "/news/notice",
    component: News,
    exact: false,
    meta: { name: "公告通知" },
  },
  { path: "/news/focus", focus: News, exact: true, meta: { name: "小镇要闻" } },
  {
    path: "/news/media",
    component: News,
    exact: true,
    meta: { name: "媒体报道" },
  },
  {
    path: "/notice/:id",
    component: Article,
    exact: true,
    meta: { name: "通知公告", pathname: "/news/notice" },
  },
  {
    path: "/media/:id",
    component: Article,
    exact: true,
    meta: { name: "媒体报道", pathname: "/news/media" },
  },
  {
    path: "/focus/:id",
    component: Article,
    exact: true,
    meta: { name: "小镇要闻", pathname: "/news/focus" },
  },
];
export default routes;
