/*
 * @Description: 小镇动态
 * @Autor: lichao
 * @Date: 2021-11-29 13:37:30
 * @LastEditors: lichao
 * @LastEditTime: 2021-12-17 16:48:59
 */
import { useState, useEffect } from "react";
import { withRouter, Route, Redirect, Switch } from "react-router-dom";
import {  getadlist } from "../../api/home";
import Banner from "../../components/Banner";
import Crumbs from "../../components/Crumbs";
import Notice from "./Notice";
import Focus from "./Focus";
import Media from "./Media";
import "./index.less";

const pathOptions = [
  { name: "小镇要闻", path: "/news/focus" },
  { name: "公告通知", path: "/news/notice" },
  { name: "媒体报道", path: "/news/media" },
];

const News = (props) => {
  const {
    location: { pathname },
  } = props;
  const BreadcrumbOptions = pathname.slice(1).split("/");
  const NewBreadcrumb = BreadcrumbOptions.map((curmb) => {
    let transCurmb = "";
    switch (curmb) {
      case "news":
        transCurmb = { meta: "小镇动态", path: "/news" };
        break;
      case "notice":
        transCurmb = { meta: "通知公告", path: "/news/notice" };
        break;
      case "media":
        transCurmb = { meta: "媒体报道", path: "/news/media" };
        break;
      case "focus":
        transCurmb = { meta: "小镇要闻", path: "/news/focus" };
        break;
      default:
        break;
    }
    return transCurmb;
  });
  const [bannerlist, setbannerlist] = useState([]);

  useEffect(() => {
    
    getadlist({ position_id: 6 }).then((res) => {
      const {
        code,
        data: { banner_list },
      } = res;
      if (code === 200) {
        setbannerlist(banner_list);
      }
    });
  }, []);
  return (
    <div className="information">
      <Banner bannerOptions={bannerlist}/>
      <Crumbs NewBreadcrumb={NewBreadcrumb} pathOptions={pathOptions} />
      <Switch>
        <Route
          path="/news/notice"
          render={(props) => <Notice meta="公告通知" />}
          exact={false}
        ></Route>
        <Route
          path="/news/focus"
          render={(props) => <Focus meta="小镇要闻" />}
          exact={false}
        ></Route>
        <Route
          path="/news/media"
          render={(props) => <Media meta="媒体报道" />}
          exact={false}
        ></Route>

        <Redirect path="/news" to="/news/focus"></Redirect>
      </Switch>
    </div>
  );
};

export default withRouter(News);
