/*
 * @Description: 环境变量配置
 * @Autor: lichao
 * @Date: 2021-11-26 09:25:01
 * @LastEditors: lichao
 * @LastEditTime: 2022-01-27 16:46:47
 */
const developmentBaseURL = "/development";

const productionBaseURL = "https://www.beijingfundtown.com";
//const productionBaseURL = "http://testweb.beijingfundtown.com:81";

export const BASEURL = process.env.NODE_ENV === "development" ? developmentBaseURL : productionBaseURL;
export const TIMEOUT = 15000;

