/*
 * @Description:
 * @Autor: lichao
 * @Date: 2021-11-29 09:08:41
 * @LastEditors: lichao
 * @LastEditTime: 2022-02-17 15:28:39
 */
import api from "../service/request";
// 获取首页数据
export const getHome = (params) => {
  return api({
    url: "/fundtown/index/gethomelist",
    method: "get",
    params,
  });
};
// 获取banner
export const getadlist = (params) => {
  return api({
    url: "/fundtown/ad/getadlist",
    method: "get",
    params,
  });
};
// 获取小镇动态列表
export const getNewList = (params) => {
  return api({
    url: "/fundtown/news/dataLists",
    method: "get",
    params,
  });
};
// 共享中心
export const getSharingCenterList = (params) => {
  return api({
    url: "/fundtown/SharingCenter/homeAdList",
    method: "get",
    params,
  });
}
// 获取小镇动态详情
export const getNewArticle = (params) => {
  return api({
    url: "/fundtown/news/datadetail",
    method: "get",
    params,
  });
};

// 获取基金会简介
export const gethomelist = (params) => {
  return api({
    url: "/fundtown/publicWelfare/gethomelist",
    method: "get",
    params,
  });
};


// 获取公益动态列表
export const getdataLists = (params) => {
  return api({
    url: "/fundtown/publicWelfare/datalist",
    method: "get",
    params,
  });
};

// 提交加入我们表单
export const submitForm = (data) => {
  return api({
    url: "/fundtown/PublicWelfare/submit",
    method: "post",
    data,
  });
};
//新增 获取基金会活动数据列表 2023年6月19日14:23:12
export const getActivityLists = (params) => {
  return api({
    url: "/fundtown/PublicWelfare/eventDataLists",
    method: "get",
    params,
  });
}
// 信息公开
export const disclosuredatalists = (params) => {
  return api({
    url: "/fundtown/PublicWelfare/disclosuredatalists",
    method: "get",
    params,
  });
};

export const partyBuildingatalists = (params) => {
  return api({
    url: "/fundtown/PartyBuilding/dataLists",
    method: "get",
    params,
  });
};

// 发展历程
export const getDevelopmentCourse = (params) => {
  return api({
    url: "/fundtown/DevelopmentCourse/getDataList",
    method: "get",
    params,
  });
};
// 获取入驻数据
export const getNumber = (params) => {
  return api({
    url: "/fundtown/index/getnowinformationdata",
    method: "get",
    params,
  });
};
