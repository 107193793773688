import { Link } from "react-router-dom";
import "./index.less";
const LiItem = (props) => {
  return (
    <li className="LiItem">
      <Link to={`/partybuilding/${props.id}`}>
        <img src="/image/xclist-left.png" alt="" />
        {props.name}
      </Link>
    </li>
  );
};
export default LiItem;
